import React, { Component } from "react";
import YawGees from "./YawGees";
import YawGeeAddEdit from "./YawGeeAddEdit";
import YawGeeHeader from "./YawGeeHeader";
import { yawgee_language } from "../../../utils/language";
import { api } from "../../../utils/urlpath";
import { user_obj } from "../../../utils/env";

export default class YawGee extends Component {
    constructor(props) {
        super(props);
        this.state = {
            is_add: false,
            is_edit: false,
            is_table: true,
            dataSet: [],
            data_obj: {},
            save_confirm: false,
            update_confirm: false,
            error_msg: ""
        };
    }

    handleEditIcon = e => {
        this.setState({
            is_edit: true,
            is_add: false,
            is_table: false,
            data_obj: e
        });
    };

    handleRemoveIcon = e => {
        fetch(api + "YawGee/deleteYawGee", {
            headers: {
                "Content-Type": "application/json"
            },
            method: "POST",
            body: JSON.stringify({
                id: e.id,
                modified_by: user_obj.current_user_id
            })
        }).then(res => {
            if (res.status) {
                this.fetchData();

                this.setState({
                    is_edit: false,
                    is_add: false,
                    is_table: true
                });
            }
        });
    };

    handleAddButton = e => {
        this.setState({
            is_edit: false,
            is_add: true,
            is_table: false
        });
        this.showActionMessage("");
    };

    handleBackButton = () => {
        this.setState({
            is_edit: false,
            is_add: false,
            is_table: true
        });
        this.showActionMessage("");
    };

    handleSave = res => {
        try {
            fetch(api + "YawGee/saveYawGee", {
                headers: {
                    "Content-Type": "application/json"
                },
                method: "POST",
                body: JSON.stringify({
                    name: res.name,
                    age: res.age,
                    nrc: res.nrc,
                    ph_number: res.ph_number,
                    address: res.address,
                    contact_person: res.contact_person,
                    contact_number: res.contact_number,
                    yawgee_number: res.yawgee_number,
                    building: res.building,
                    room: res.room,
                    difficulties: res.difficulties,
                    created_by: user_obj.current_user_id,
                    modified_by: user_obj.current_user_id
                })
            })
                .then(data => data.json())
                .then(new_obj => {
                    if (new_obj.status) {
                        // console.log(new_obj.data);
                        this.fetchData();
                        this.setState({
                            // dataSet: [...this.state.dataSet, new_obj.data],
                            is_table: true,
                            is_edit: false,
                            is_add: false
                        });
                        this.showActionMessage("");
                    } else {
                        this.showActionMessage(new_obj.text);
                    }
                })
                .catch(e => {
                    this.showActionMessage(e.text);
                    console.log(e);
                });
        } catch (ex) {
            console.log(ex);
        }
    };

    handleUpdate = res => {
        try {
            fetch(api + "YawGee/updateYawGee", {
                headers: {
                    // Accept: "application/json",
                    "Content-Type": "application/json"
                },
                method: "POST",
                body: JSON.stringify({
                    id: res.id,
                    name: res.name,
                    age: res.age,
                    nrc: res.nrc,
                    ph_number: res.ph_number,
                    address: res.address,
                    contact_person: res.contact_person,
                    contact_number: res.contact_number,
                    yawgee_number: res.yawgee_number,
                    building: res.building,
                    room: res.room,
                    difficulties: res.difficulties,
                    modified_by: user_obj.current_user_id
                })
            })
                .then(data => data.json())
                .then(new_obj => {
                    // console.log(new_obj.data);
                    if (new_obj.status) {
                        this.fetchData();
                        this.showActionMessage("");
                        this.setState({
                            is_table: true,
                            is_edit: false,
                            is_add: false
                        });
                    } else {
                        this.showActionMessage(new_obj.text);
                    }
                })
                .catch(e => {
                    this.showActionMessage(e.text);

                    console.log(e);
                });
        } catch (ex) {
            console.log(ex);
        }
    };

    showActionMessage = text => {
        let error_msg = "";
        const lan = this.props.langIndex;

        if (text === "Duplicated") {
            error_msg = yawgee_language[lan].actionDuplicate;
        } else if (text === "Not Saved") {
            error_msg = yawgee_language[lan].actionSaveFail;
        } else if (text === "Not Updated") {
            error_msg = yawgee_language[lan].actionUpdateFail;
        }

        this.setState({ error_msg });
    };

    fetchData() {
        // console.log("Fetching Data..");
        try {
            fetch(api + "YawGee/getAllYawGee")
                .then(response => response.json())
                .then(res => {
                    if (res != null || res.length > 0) {
                        this.setState({ dataSet: res.data });
                    } else console.log("Somthings wrong in database");
                });
        } catch (ex) {
            console.log(ex);
        }
    }

    componentDidMount() {
        this.fetchData();
    }

    render() {
        // console.log(this.state.dataSet);
        return (
            <div>
                {this.state.is_table ? (
                    <React.Fragment>
                        <YawGeeHeader
                            langIndex={this.props.langIndex}
                            isEdit={this.state.is_edit}
                            isAdd={this.state.is_add}
                            isTable={this.state.is_table}
                            onAddButtonClick={this.handleAddButton}
                        />
                        <YawGees
                            langIndex={this.props.langIndex}
                            onEditIconClick={this.handleEditIcon}
                            onRemoveIconClick={this.handleRemoveIcon}
                            dataSet={this.state.dataSet}
                        />
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <YawGeeHeader
                            langIndex={this.props.langIndex}
                            isEdit={this.state.is_edit}
                            isAdd={this.state.is_add}
                            isTable={this.state.is_table}
                            onBackButtonClick={this.handleBackButton}
                        />
                        <YawGeeAddEdit
                            langIndex={this.props.langIndex}
                            isEdit={this.state.is_edit}
                            isAdd={this.state.is_add}
                            dataObj={this.state.data_obj}
                            onSave={this.handleSave}
                            onUpdate={this.handleUpdate}
                            errorMsg={this.state.error_msg}
                        />
                    </React.Fragment>
                )}
            </div>
        );
    }
}
