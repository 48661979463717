import React, { Component } from "react";
const $ = require("jquery");

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = { username: "", password: "" };
  }

  handleChangeUsername = e => {
    this.setState({ username: e.target.value });
  };

  handleChangePassword = e => {
    this.setState({ password: e.target.value });
  };

  handleKeyPress = target => {
    if (target.charCode === 13) {
      $("#login_btn").click();
      return false;
    }
  };

  componentDidMount() {
    console.log("LOGIN DID MOUNT ///////////");
  }

  render() {
    // console.log(this.props.errorMsg);

    return (
      // <div className="Login">
      <div>
        <div className="login-header">
          <img
            className="logo-login"
            src="assets/image/temple-mrauk-u-myanmar.jpg"
          />
          <span className="pl-3">MyaukOo</span>
        </div>
        <div className="Login d-flex justify-content-center">
          <div className="card login-card ">
            <div className="card-header login-card-header">
              <h3>Log In</h3>
            </div>
            <div className="card-body login-body">
              <form>
                <div className="input-group form-group  pb-2">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <i className="fa fa-user" />
                    </span>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="username"
                    value={this.state.username}
                    onChange={this.handleChangeUsername}
                    onKeyPress={this.handleKeyPress}
                  />
                </div>
                <div className="input-group form-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <i className="fa fa-key" />
                    </span>
                  </div>
                  <input
                    type="password"
                    className="form-control"
                    placeholder="password"
                    value={this.state.password}
                    onChange={this.handleChangePassword}
                    onKeyPress={this.handleKeyPress}
                  />
                </div>
                <div>
                  <span className="action-error">{this.props.errorMsg}</span>
                </div>

                <div className="form-group">
                  <input
                    type="button"
                    value="Login"
                    id="login_btn"
                    className="btn float-right login_btn"
                    onClick={() => {
                      this.props.onLoginClick(
                        this.state.username,
                        this.state.password
                      );
                    }}
                  />
                </div>
              </form>
              <br />
              <br />

              {/* <div className="card-footer">
                <div className="d-flex justify-content-center links login-links">
                  Don't have an account?
                  <span className="sign-up" onClick={this.props.onSignUpClick}>
                    Sign Up
                  </span>
                </div>
                <div className="d-flex justify-content-center">
                  <a href="#">Forgot your password?</a>
                </div>
              </div> */}
            </div>
          </div>
        </div>
        {/* </div> */}
      </div>
    );
  }
}

export default Login;
