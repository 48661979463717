import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import YawGee from "../Setups/YawGee/YawGee";
// import YawGees from "../Setups/YawGee/YawGees";
import Login from "../Login/Login";
import UserAccount from "../Setups/UserAccount/UserAccount";

export default class Routing extends Component {
  render() {
    return (
      <div id="sub-content">
        <switch>
          <Route
            path="/Login"
            render={() => <Login langIndex={this.props.langIndex} />}
          />
          <Route
            path="/"
            exact
            render={() => <YawGee langIndex={this.props.langIndex} />}
          />
          <Route
            path="/YawGee"
            render={() => <YawGee langIndex={this.props.langIndex} />}
          />
          <Route
            path="/UserAccount"
            render={() => <UserAccount langIndex={this.props.langIndex} />}
          />
        </switch>
      </div>
    );
  }
}
