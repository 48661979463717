import React, { Component } from "react";
import UserAccounts from "./UserAccounts";
import UserAccountHeader from "./UserAccountHeader";
import UserAccountAddEdit from "./UserAccountAddEdit";
import { api } from "../../../utils/urlpath";
import { user_obj } from "../../../utils/env";
import { useraccount_language } from "../../../utils/language";

class UserAccount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      is_add: false,
      is_edit: false,
      is_table: true,
      dataSet: [],
      data_obj: {},
      save_confirm: false,
      update_confirm: false,
      error_msg: "",
      is_approved: true
    };
  }

  handleEditIcon = e => {
    this.setState({
      is_edit: true,
      is_add: false,
      is_table: false,
      data_obj: e
    });
  };

  handleRemoveIcon = e => {
    fetch(api + "Useraccount/deleteUseraccount", {
      headers: {
        "Content-Type": "application/json"
      },
      method: "POST",
      body: JSON.stringify({
        id: e.id,
        modified_by: user_obj.current_user_id
      })
    }).then(res => {
      if (res.status) {
        this.fetchData();
        this.setState({
          is_edit: false,
          is_add: false,
          is_table: true
        });
      }
    });
  };

  handleAddButton = e => {
    this.setState({
      is_edit: false,
      is_add: true,
      is_table: false
    });
    this.showActionMessage("");
  };

  handleBackButton = () => {
    this.setState({
      is_edit: false,
      is_add: false,
      is_table: true
    });
    this.showActionMessage("");
  };

  handleSave = res => {
    console.log(res);
    try {
      fetch(api + "Useraccount/saveUseraccount", {
        headers: {
          "Content-Type": "application/json"
        },
        method: "POST",
        body: JSON.stringify({
          username: res.username,
          password: res.password,
          confirmpassword: res.confirmpassword,
          is_approved: res.is_approved,
          created_by: user_obj.current_user_id,
          modified_by: user_obj.current_user_id
        })
      })
        .then(data => data.json())
        .then(new_obj => {
          if (new_obj.status) {
            this.fetchData();
            this.setState({
              // dataSet: [...this.state.dataSet, new_obj.data],
              is_table: true,
              is_edit: false,
              is_add: false
            });
            this.showActionMessage("");
          } else {
            this.showActionMessage(new_obj.text);
          }
        })
        .catch(e => {
          this.showActionMessage(e.text);
          console.log(e);
        });
    } catch (ex) {
      console.log(ex);
    }
  };

  handleUpdate = res => {
    try {
      fetch(api + "Useraccount/updateUseraccount", {
        headers: {
          // Accept: "application/json",
          "Content-Type": "application/json"
        },
        method: "POST",
        body: JSON.stringify({
          id: res.id,
          username: res.username,
          password: res.password,
          confirmpassword: res.confirmpassword,
          is_approved: res.is_approved,
          modified_by: user_obj.current_user_id
        })
      })
        .then(data => data.json())
        .then(new_obj => {
          if (new_obj.status) {
            this.fetchData();
            this.showActionMessage("");
            this.setState({
              is_table: true,
              is_edit: false,
              is_add: false
            });
          } else {
            this.showActionMessage(new_obj.text);
          }
        })
        .catch(e => {
          this.showActionMessage(e.text);

          console.log(e);
        });
    } catch (ex) {
      console.log(ex);
    }
  };

  showActionMessage = text => {
    let error_msg = "";
    const lan = this.props.langIndex;

    if (text === "Duplicated") {
      error_msg = useraccount_language[lan].actionDuplicate;
    } else if (text === "Not Saved") {
      error_msg = useraccount_language[lan].actionSaveFail;
    } else if (text === "Not Updated") {
      error_msg = useraccount_language[lan].actionUpdateFail;
    }

    this.setState({ error_msg });
  };

  fetchData = () => {
    try {
      fetch(api + "Useraccount/getAllUseraccount")
        .then(response => response.json())
        .then(res => {
          if (res != null || res.length > 0) {
            console.log(res.data);
            this.setState({ dataSet: res.data });
          } else console.log("Somthings wrong in database");
        });
    } catch (ex) {
      console.log(ex);
    }
  };

  componentDidMount() {
    this.fetchData();
  }

  render() {
    return (
      <div>
        {this.state.is_table ? (
          <React.Fragment>
            <UserAccountHeader
              langIndex={this.props.langIndex}
              isEdit={this.state.is_edit}
              isAdd={this.state.is_add}
              isTable={this.state.is_table}
              onAddButtonClick={this.handleAddButton}
            />
            <UserAccounts
              langIndex={this.props.langIndex}
              onEditIconClick={this.handleEditIcon}
              onRemoveIconClick={this.handleRemoveIcon}
              dataSet={this.state.dataSet}
            />
          </React.Fragment>
        ) : (
          <React.Fragment>
            <UserAccountHeader
              langIndex={this.props.langIndex}
              isEdit={this.state.is_edit}
              isAdd={this.state.is_add}
              isTable={this.state.is_table}
              onBackButtonClick={this.handleBackButton}
            />
            <UserAccountAddEdit
              langIndex={this.props.langIndex}
              isEdit={this.state.is_edit}
              isAdd={this.state.is_add}
              dataObj={this.state.data_obj}
              onSave={this.handleSave}
              onUpdate={this.handleUpdate}
              errorMsg={this.state.error_msg}
            />
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default UserAccount;
