import React, { Component } from "react";
import {
  useraccount_language,
  addedit_language
} from "../../../utils/language";
import { api } from "../../../utils/urlpath";
import Select from "react-select";
var md5 = require("md5");

class UserAccountAddEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",

      username: "",
      password: "",
      confirmpassword: "",

      is_approved: true,
      password_error: ""
    };
  }

  clearStates() {
    try {
      this.setState({
        id: "",

        username: "",
        password: "",
        confirmpassword: "",

        is_approved: true,
        password_error: ""
      });
    } catch (ex) {
      console.log(ex);
    }
  }

  fillForm() {
    this.clearStates();
    if (this.props.isEdit) {
      console.log("FIlL FORM>>>>>>>>");
      console.log(this.props.dataObj);
      let dataObj = this.props.dataObj;
      this.setState({
        id: dataObj.id,

        username: dataObj.username,
        password: dataObj.password,
        confirmpassword: "",

        is_approved: dataObj.is_approved
      });
    }
  }

  componentDidMount() {
    console.log("DID MOUNT>>>>>>>>>>>>>>>>>");
    console.log(this.props.dataObj);
    this.fillForm();
  }

  handleChangeApprove = () => {
    this.setState({ is_approved: !this.state.is_approved });
  };

  handleChangeUsername = e => {
    this.setState({ username: e.target.value });
  };

  handleChangePassword = e => {
    console.log("Changing Password");
    console.log(e.target.value);
    this.setState({ password: e.target.value });
  };

  handleChangeConfirmPassword = e => {
    this.setState({ confirmpassword: e.target.value });
  };

  validate() {
    return {
      username: this.state.username === "",
      password: this.state.password === "",
      confirmpassword: this.state.confirmpassword === ""
      //is_approved: this.state.is_approved === ""
    };
  }

  checkPassword = () => {
    let psw = true;
    let confirm_password = "";

    if (this.props.isAdd) {
      confirm_password = this.state.confirmpassword;
    } else {
      confirm_password = md5(this.state.confirmpassword);
    }

    if (this.state.password !== confirm_password) {
      psw = false;
      this.setState({
        password_error: " Password and Confirm Password Not Match !"
      });
    } else {
      this.setState({
        password_error: ""
      });
    }
    console.log(psw);
    return psw;
  };

  onSubmit = () => {
    console.log(this.props);

    if (this.checkPassword()) {
      if (this.props.isAdd) {
        let DataObj = {
          username: this.state.username,
          password: md5(this.state.password),
          is_approved: this.state.is_approved

          //id: this.state.id
        };
        this.props.onSave(DataObj);
      } else {
        let DataObj = {
          username: this.state.username,
          password: md5(this.state.password),
          confirmpassword: this.state.confirmpassword,
          is_approved: this.state.is_approved,
          id: this.state.id
        };
        this.props.onUpdate(DataObj);
      }
    }
  };

  render() {
    const lan = this.props.langIndex;
    let errors = this.validate();
    const isDisable = Object.keys(errors).some(x => errors[x]);

    return (
      <div className="card card-body mt-3">
        <form>
          <div class="form-group row">
            <label for="username" class="col-sm-3 col-form-label">
              {useraccount_language[lan].username}
              <span className="text-danger">*</span>
            </label>
            <div class="col-sm-8">
              <input
                type="text"
                class="form-control"
                value={this.state.username}
                onChange={this.handleChangeUsername}
              />
            </div>
          </div>

          {this.props.isAdd ? (
            <div class="form-group row">
              <label for="inputPassword" class="col-sm-3 col-form-label">
                {useraccount_language[lan].password}
                <span className="text-danger">*</span>
              </label>
              <div class="col-sm-8">
                <input
                  type="password"
                  class="form-control"
                  value={this.state.password}
                  onChange={this.handleChangePassword}
                />
              </div>
            </div>
          ) : (
            ""
          )}

          <div class="form-group row">
            <label for="inputPassword" class="col-sm-3 col-form-label">
              {useraccount_language[lan].password}
              <span className="text-danger">*</span>
            </label>
            <div class="col-sm-8">
              <input
                type="password"
                class="form-control"
                value={this.state.confirmpassword}
                onChange={this.handleChangeConfirmPassword}
              />

              <small className="text-muted error-txt">
                {this.state.password_error}
              </small>
            </div>
          </div>
          <div className="form-group row">
            <label class="col-sm-3 form-check-label" for="approve">
              {useraccount_language[lan].approve}
            </label>
            <div className="col-sm-8 ">
              <div class="pretty p-default p-curve p-thick">
                <input
                  type="checkbox"
                  checked={this.state.is_approved}
                  onChange={this.handleChangeApprove}
                />
                <div className="state p-primary">
                  <label />
                </div>
              </div>
            </div>
          </div>
          <div className="action-error">
            <span>{this.props.errorMsg}</span>
          </div>
          <button
            type="button"
            class="btn btn-primary pull-right"
            onClick={this.onSubmit}
            disabled={isDisable}
          >
            <i class="fa fa-save"> </i>
            &nbsp;{addedit_language[lan].save}
          </button>
        </form>
      </div>
    );
  }
}

export default UserAccountAddEdit;
