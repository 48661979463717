import React, { Component } from "react";
import {
  useraccount_language,
  addedit_language
} from "../../../utils/language";

import "datatables.net-bs4/css/dataTables.bootstrap4.min.css";
import "datatables.net-responsive-bs4/css/responsive.bootstrap4.min.css";

var remove_data = {};
var $ = require("jquery");

require("datatables.net-responsive-bs4");
require("datatables.net-bs4");

class UserAccounts extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.LoadData();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.dataSet !== this.props.dataSet ||
      prevProps.langIndex !== this.props.langIndex
    ) {
      this.LoadData();
    }
  }

  handleConfirmRemove = () => {
    this.props.onRemoveIconClick(remove_data);
  };

  LoadData = () => {
    let that = this;
    this.formatTable(this.props.dataSet);

    $("#dataTables-table").on("click", "#btn_remove", function() {
      remove_data = $(this)
        .find("#remove")
        .text();
      remove_data = $.parseJSON(remove_data);
    });

    $("#dataTables-table").on("click", "#btn_edit", function() {
      var data = $(this)
        .find("#edit")
        .text();
      data = $.parseJSON(data);
      that.props.onEditIconClick(data);
    });
  };

  formatTable(res) {
    console.log(res);
    let lan = this.props.langIndex;
    let column_data = [
      { title: useraccount_language[lan].username, data: "username" },

      { title: addedit_language[lan].edit, data: "Edit" },
      { title: addedit_language[lan].remove, data: "Delete" }
    ];

    if ($.fn.dataTable.isDataTable("#dataTables-table")) {
      let table = $("#dataTables-table").dataTable();
      table.fnClearTable();
      table.fnDestroy();
      $("#dataTables-table").empty();
    }

    $("#dataTables-table").dataTable({
      columns: column_data,
      data: res,
      columnDefs: [
        {
          targets: -2,
          render: function(a, b, data, d) {
            return (
              "<i class='edit-style fa fa-edit edit-style' id='btn_edit' data-toggle='modal' data-target='#addModal' > <span id='edit' class='d-none' >" +
              JSON.stringify(data) +
              "</span></i>"
            );
          }
        },
        {
          targets: -1,
          render: function(a, b, data, d) {
            return (
              "<i class='remove-style fa fa-times' id='btn_remove' data-toggle='modal' data-target='#removeModal'> <span id='remove' class='d-none' >" +
              JSON.stringify(data) +
              "</span></i>"
            );
          }
        }
      ]
    });
  }

  render() {
    const lan = this.props.langIndex;
    return (
      <React.Fragment>
        <div className="card card-body mt-3">
          <table
            width="99%"
            className="table table-striped table-bordered table-hover responsive nowrap dt-responsive"
            id="dataTables-table"
          />

          <div
            class="modal fade"
            id="removeModal"
            tabindex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabel">
                    {useraccount_language[lan].removeModalTitle}
                  </h5>
                  <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  {useraccount_language[lan].removeConfirmation}
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn btn-secondary"
                    data-dismiss="modal"
                  >
                    {addedit_language[lan].cancel}
                  </button>
                  <button
                    type="button"
                    class="btn btn-danger"
                    onClick={this.handleConfirmRemove}
                    data-dismiss="modal"
                  >
                    {addedit_language[lan].removeConfirm}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default UserAccounts;
