import React, { Component } from "react";
import { yawgee_language, addedit_language } from "../../../utils/language";

export default class YawGeeHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            title: ""
        };
    }

    setFormTitle() {
        let lan = this.props.langIndex;
        let title = "";

        if (this.props.isTable) {
            title = yawgee_language[lan].viewTitle;
        } else if (this.props.isAdd) {
            title = yawgee_language[lan].newTitle;
        } else {
            title = yawgee_language[lan].editTitle;
        }
        this.setState({ title });
    }

    componentDidMount() {
        this.setFormTitle();
    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props) {
            this.setFormTitle();
        }
    }

    render() {
        let lan = this.props.langIndex;

        return (
            <div className="card form-title">
                <div className="form-row">
                    <div className="col-6 ">
                        <label className="card-label">{this.state.title}</label>
                    </div>
                    <div className="col-6">
                        {this.props.isTable ? (
                            <button
                                className="btn btn-info pull-right"
                                onClick={this.props.onAddButtonClick}
                            >
                                <i className="fa fa-plus"> </i>
                                &nbsp; {addedit_language[lan].new}
                            </button>
                        ) : (
                            <button
                                className="btn btn-secondary pull-right"
                                onClick={this.props.onBackButtonClick}
                            >
                                <i className="fa fa-angle-left"></i>
                                &nbsp; {addedit_language[lan].back}
                            </button>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}
