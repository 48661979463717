import React, { Component } from "react";
import { menu_language } from "../../utils/language";
import { Link } from "react-router-dom";

export default class Sidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      is_visible_special_setup: true
    };
  }

  render() {
    const lan = this.props.langIndex;
    return (
      <div className="wrapper">
        <nav id="sidebar" className="sidebar">
          <div className="sidebar-header">
            <img
              alt="logo"
              className="logostyle"
              src="assets/image/temple-mrauk-u-myanmar.jpg"
            />
            <br />
            <span className="fontWhite">Admin</span>
            <br />
            <br />
            {/* <p className="text-sm mb-0">{user_obj.department_name}</p> */}
            {/* <p className="text-sm">Developer</p> */}
          </div>
          <ul className="components">
            <li className="parent_class ">
              <Link to="/YawGee">
                <i className="fa fa-user-friends mr-2" />{" "}
                {menu_language[lan].yawgee}
              </Link>
            </li>
            <li className="parent_class ">
              <Link to="/UserAccount">
                <i className="fa fa-user-friends mr-2" />{" "}
                {menu_language[lan].useraccount}
              </Link>
            </li>
          </ul>
        </nav>
      </div>
    );
  }
}
