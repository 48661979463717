// <----- BACKEND RESPONSE TEXT MESSAGE ----->

// Fetch: Success - Fetched
// Error - Oops! Somethind wrong

// Save: Success - Saved
// Fail - Not Saved
// Error - Oops! Somethind wrong
// Duplicate - Duplicated

// Delete: Success - Deleted
// Fail - Not Deleted
// Error - Oops! Delete Fail

// Update: Sucess - Updated
// Fail - Not Updated
// Error - Oops! Somethind wrong
// Duplicate - Duplicated

// < ----- BACKEND RESPONSE TEXT MESSAGE----->

export const menu_language = [
  {
    language: "Language",
    account: "Account",
    logout: "Sign Out",
    change_password: "Change Password",
    dashboard: "Dashboard",
    home: "Home",
    yawgee: "Yawgee",
    projects: "Projects",
    approve_project: "Approve Project",
    project_implementation: "Project Implementation",
    upload: "Upload",
    comment: "Comment",
    payment: "Payment",
    setups: "Setups",
    special_setups: "Special Setups",
    budget_type: "Budget Type",
    company: "Company",
    department: "Department",
    designation: "Designation",
    reason: "Reason",
    user_role: "User Role",
    employee: "Employee",
    useraccount: "User Account",
    designationcontrol: "Designation Control",
    role_control: "User Role Control",
    projectcategory: "Project Category",
    projectsubcategory: "Project SubCategory",
    inbox_note_sheet: "Inbox Note Sheet",
    securitylevel: "Security Level",
    prioritylevel: "Priority Level",
    inboxtype: "Inbox Type",
    outboxtype: "Outbox Type",
    inbox: "inbox",
    outbox: "outbox",
    complainbox: "complainbox",
    cases: "Cases",
    case_category: "Case Category",
    map_view: "Map View",
    revisereason: "Revise Reason"
  },
  {
    language: "ဘာသာစကား",
    account: "အကောင့်",
    logout: "ထွက်မည်",
    change_password: "Passwordချိန်းရန်",
    dashboard: "သုံးသပ်ချက်များ ကြည့်၇န်",
    dashboard: "Dashboard",
    home: "မူရင်း",
    yawgee: "Yawgee",
    projects: "စီမံကိန်းများ",
    approve_project: "စီမံကိန်း ခွင့်ပြုရန်",
    project_implementation: "စီမံချက်များ ထည့်ရန်",
    upload: "အချက်အလက်များ ထည့်၇န်",
    comment: "ထင်မြင်ချက်များ ပေးရန်",
    payment: "ငွေပေးချေရန်",
    setups: "သတ်မှတ်ချက်များ",
    special_setups: "အထူးသတ်မှတ်ချက်များ",
    budget_type: "ဘတ်ဂျက်အမျိုးစား",
    company: "ကုမ္ပဏီ",
    department: "ဌာန",
    designation: "ရာထူး",
    reason: "စီမံကိန်းလုပ်၇သည့် အကြောင်း၇ာ",
    user_role: "အသုံးပြုသူ ကဏ္ဍ",
    employee: "၀န်ထမ်း",
    useraccount: "၀န်ထမ်း အကောင့်",
    designationcontrol: "လုပ်ပိုင်ခွင့်ရာထူး",
    role_control: "လုပ်ပိုင်ခွင့်ကဏ္ဍ",
    projectcategory: "စီမံကိန်းအမျိုးအစား",
    projectsubcategory: "စီမံကိန်းအမျိုးအစားခွဲ",
    inbox_note_sheet: "Inbox Note Sheet",
    securitylevel: "လုံခြုံရေးအဆင့်အတန်း",
    prioritylevel: "ဦးစားပေးအဆင့်အတန်း",
    inboxtype: "ဝင်စာအမျိုးအစား",
    outboxtype: "ထွက်စာအမျိုးအစား",
    inbox: "ဝင်စာ",
    outbox: "ထွက်စာ",
    complainbox: "တိုင်စာ",
    cases: "အမှုတွဲများ",
    case_category: "အမှုတွဲ အမျိုးအစား",
    map_view: "မြေပုံ",
    revisereason: "Revise Reason"
  }
];

// <-- Passwrod -->
export const password_language = [
  {
    id: "Password ID",
    old_password: "Old Password",
    new_password: "New Password",
    confirm_password: "Confirm New Password",
    modal_title: "Change Password",
    actionDuplicate: "Sorry, Cannot Save! Plz enter the correct old password.",
    actionSaveFail:
      "Sorry, Cannot Save! The new password and confirm password is not matched.",
    actionUpdateFail: "Sorry, Cannot Update! Something went wrong."
  },
  {
    id: "Password ID",
    old_password: "Old Password",
    new_password: "New Password",
    confirm_password: "Confirm New Password",
    modal_title: "Change Password",
    actionWrongPassword: "မှန်ကန်သော လှို့ဝှက်နံပါတ်အဟောင်းကို ရိုက်ထည့်ပေးပါ",
    actionSaveFail:
      "New Passwordနှင့် Confirm Password မတူညီသောကြောင့် သိမ်းဆည်းခြင်းမအောင်မြင်ပါ။",
    actionUpdateFail:
      "တစ်စုံတစ်ခု မှားယွင်းသွားသောကြောင့် ပြင်ဆင်ခြင်းမအောင်မြင်ပါ။"
  }
];
//  <-- SETUP Language -->

export const yawgee_language = [
  {
    id: "ID",
    name: "Name",
    age: "Age",
    nrc: "NRC",
    ph_number: "Ph Number",
    address: "Addresse",
    contact_person: "Contact person",
    contact_number: "Contact Number",
    yawGee_number: "YawGee Number",
    building: "Building",
    room: "Room",
    difficulties: "Is there any difficulties?",
    viewTitle: "Yawgee Lists",
    newTitle: "Add New Yawgee",
    editTitle: "Edit Yawgee",
    removeConfirmation: "Are you sure to Remove",
    removeModalTitle: "Removing YawGee",
    actionDuplicate:
      "Sorry, Cannot Save! Same YawGee already exists in system.",
    actionSaveFail: "Sorry, Cannot Save! Something went wrong.",
    actionUpdateFail: "Sorry, Cannot Update! Something went wrong."
  },
  {
    id: "အမှတ်စဉ်",
    name: "အမည်",
    age: "အသက်",
    nrc: "နိုင်ငံသားအမှတ်",
    ph_number: "ဖုန်း",
    address: "နေရပ်လိပ်စာ",
    contact_person: "ဆက်သွယ်နိုင်မည့်ဆွေမျိုးသားချင်းအမည်",
    contact_number: "ဆက်သွယ်နိုင်မည့်ဆွေမျိုးသားချင်းဖုန်းနံပါတ်",
    yawGee_number: "ယောဂီအမှတ်စဉ်",
    building: "နေရာချထားပေးမည့်အဆောက်အအုံ",
    room: "အခန်းနံပါတ်",
    difficulties: "အခက်အခဲတစ်စုံတစ်ရာရှိပါသလား",
    viewTitle: "ယောဂီများ",
    newTitle: "ယောဂီ အသစ်ထည့်ခြင်း",
    editTitle: "ယောဂီ ပြင်ဆင်ခြင်း",
    removeConfirmation: "ယခု ယောဂီ ဖျက်ရန်သေချာ ပါသလား ?",
    removeModalTitle: "ယောဂီ ဖျက်ခြင်း ",
    actionDuplicate:
      "တူညီသော ဘတ်ဂျက်အမျိုးအစား ရှိပြီးဖြစ်၍ သိမ်းဆည်းခြင်းမအောင်မြင်ပါ။",
    actionSaveFail:
      "တစ်စုံတစ်ခု မှားယွင်းသွားသောကြောင့် သိမ်းဆည်းခြင်းမအောင်မြင်ပါ။",
    actionUpdateFail:
      "တစ်စုံတစ်ခု မှားယွင်းသွားသောကြောင့် ပြင်ဆင်ခြင်းမအောင်မြင်ပါ။"
  }
];

export const useraccount_language = [
  {
    employee: "Name",
    department: "Department",
    designation: "Designation",
    username: "Username",
    nrc: "Nrc",
    password: "Password",
    confirmpassword: "Confirm Password",
    role: "Role",
    viewTitle: "User Account Lists",
    newTitle: "Add New User Account",
    editTitle: "Edit User Account",
    removeConfirmation: "Are you sure to Remove",
    removeModalTitle: "Removing User Account",
    approve: "Approve",
    actionDuplicate:
      "Sorry, Cannot Save! Same User Account already exists in system.",
    actionSaveFail: "Sorry, Cannot Save! Something went wrong.",
    actionUpdateFail: "Sorry, Cannot Update! Something went wrong."
  },
  {
    employee: "အမည်",
    department: "ဌာန",
    designation: "ရာထူး",
    username: "အသုံးပြုသူ အမည်",
    nrc: "အမျိုးသားမှတ်ပုံတင် နံပါတ်",
    password: "စကားဝှက်",
    confirmpassword: "စကားဝှက် ပြန်ရိုက်ထည့်ရန်",
    role: "အသုံးပြုသူ ကဏ္ဍ",
    viewTitle: "၀န်ထမ်း အကောင့်များ ",
    newTitle: "၀န်ထမ်း အကောင့် အသစ်ထည့်ခြင်း",
    editTitle: "၀န်ထမ်း အကောင့် ပြင်ဆင်ခြင်း",
    removeConfirmation: "ယခု ၀န်ထမ်း အကောင့် ဖျက်ရန်သေချာ ပါသလား ?",
    removeModalTitle: "၀န်ထမ်း အကောင့် ဖျက်ခြင်း",
    approve: "အတည်ပြုသည်",
    actionDuplicate:
      "တူညီသော ၀န်ထမ်း အကောင့် ရှိပြီးဖြစ်၍ သိမ်းဆည်းခြင်းမအောင်မြင်ပါ။",
    actionSaveFail:
      "တစ်စုံတစ်ခု မှားယွင်းသွားသောကြောင့် သိမ်းဆည်းခြင်းမအောင်မြင်ပါ။",
    actionUpdateFail:
      "တစ်စုံတစ်ခု မှားယွင်းသွားသောကြောင့် ပြင်ဆင်ခြင်းမအောင်မြင်ပါ။"
  }
];

export const addedit_language = [
  {
    edit: "Edit",
    remove: "Remove",
    new: "New",
    back: "Back",
    save: "Save",
    cancel: "Cancel",
    removeConfirm: "Remove",
    case_back: "Back To Case"
  },
  {
    edit: "ကြည့်ရန်",
    remove: "ဖျက်ရန်",
    new: "အသစ်ထည့်ရန်",
    back: "နောက်သို့",
    save: "သိမ်းရန်",
    cancel: "မလုပ်ပါ",
    removeConfirm: "သေချာသည်",
    case_back: "Case သို့"
  }
];
