import React, { Component } from "react";
import { yawgee_language, addedit_language } from "../../../utils/language";

class YawGeeAddEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      name: "",
      age: "",
      nrc: "",
      ph_number: "",
      address: "",
      contact_person: "",
      contact_number: "",
      yawgee_number: "",
      building: "",
      room: "",
      difficulties: ""
    };
  }

  fillForm() {
    if (this.props.isEdit) {
      let dataObj = this.props.dataObj;
      console.log(dataObj);
      this.setState({
        id: dataObj.id,
        name: dataObj.name,
        age: dataObj.age,
        nrc: dataObj.nrc,
        ph_number: dataObj.ph_number,
        address: dataObj.address,
        contact_person: dataObj.contact_person,
        contact_number: dataObj.contact_number,
        yawgee_number: dataObj.yawgee_number,
        building: dataObj.building,
        room: dataObj.room,
        difficulties: dataObj.difficulties
      });
    }
  }

  componentDidMount() {
    console.log("DID MOUNT>>>>>>>>>>>>>>>>>");
    console.log(this.props.dataObj);
    this.fillForm();
  }

  handleChangeName = e => {
    this.setState({ name: e.target.value });
  };

  handleChangeAge = e => {
    this.setState({ age: e.target.value });
  };

  handleChangeNrc = e => {
    this.setState({ nrc: e.target.value });
  };

  handleChangePhNumber = e => {
    this.setState({ ph_number: e.target.value });
  };

  handleChangeAddress = e => {
    this.setState({ address: e.target.value });
  };

  handleChangeContactPerson = e => {
    this.setState({ contact_person: e.target.value });
  };

  handleChangeContactNumber = e => {
    this.setState({ contact_number: e.target.value });
  };

  handleChangeYawGeeNumber = e => {
    this.setState({ yawgee_number: e.target.value });
  };

  handleChangeBuilding = e => {
    this.setState({ building: e.target.value });
  };

  handleChangeRoom = e => {
    this.setState({ room: e.target.value });
  };

  handleChangeDifficulties = e => {
    this.setState({ difficulties: e.target.value });
  };

  validate() {
    return {
      name: this.state.name === ""
    };
  }

  onSubmit = () => {
    let DataObj = {};
    if (this.props.isAdd) {
      DataObj = {
        name: this.state.name,
        age: this.state.age,
        nrc: this.state.nrc,
        ph_number: this.state.ph_number,
        address: this.state.address,
        contact_person: this.state.contact_person,
        contact_number: this.state.contact_number,
        yawgee_number: this.state.yawgee_number,
        building: this.state.building,
        room: this.state.room,
        difficulties: this.state.difficulties
      };
      this.props.onSave(DataObj);
    } else {
      DataObj = {
        name: this.state.name,
        age: this.state.age,
        nrc: this.state.nrc,
        ph_number: this.state.ph_number,
        address: this.state.address,
        contact_person: this.state.contact_person,
        contact_number: this.state.contact_number,
        yawgee_number: this.state.yawgee_number,
        building: this.state.building,
        room: this.state.room,
        difficulties: this.state.difficulties,
        id: this.state.id
      };
      this.props.onUpdate(DataObj);
    }
  };

  render() {
    const lan = this.props.langIndex;
    let errors = this.validate();

    const isDisable = Object.keys(errors).some(x => errors[x]);
    return (
      <div className="card card-body mt-3">
        <form>
          <div class="form-group row">
            <label for="staticEmail" class="col-sm-2 col-form-label">
              {yawgee_language[lan].name}
              <span className="text-danger">*</span>
            </label>
            <div class="col-sm-8">
              <input
                type="text"
                class="form-control"
                id="name"
                value={this.state.name}
                onChange={this.handleChangeName}
              />
            </div>
          </div>
          <div class="form-group row">
            <label for="inputPassword" class="col-sm-2 col-form-label">
              {yawgee_language[lan].age}
            </label>
            <div class="col-sm-8">
              <input
                type="number"
                class="form-control"
                id="description"
                value={this.state.age}
                onChange={this.handleChangeAge}
              />
            </div>
          </div>
          <div class="form-group row">
            <label for="staticEmail" class="col-sm-2 col-form-label">
              {yawgee_language[lan].nrc}
              <span className="text-danger">*</span>
            </label>
            <div class="col-sm-8">
              <input
                type="text"
                class="form-control"
                id="name"
                value={this.state.nrc}
                onChange={this.handleChangeNrc}
              />
            </div>
          </div>
          <div class="form-group row">
            <label for="staticEmail" class="col-sm-2 col-form-label">
              {yawgee_language[lan].ph_number}
              <span className="text-danger">*</span>
            </label>
            <div class="col-sm-8">
              <input
                type="number"
                class="form-control"
                id="name"
                value={this.state.ph_number}
                onChange={this.handleChangePhNumber}
              />
            </div>
          </div>
          <div class="form-group row">
            <label for="staticEmail" class="col-sm-2 col-form-label">
              {yawgee_language[lan].address}
              <span className="text-danger">*</span>
            </label>
            <div class="col-sm-8">
              <input
                type="text"
                class="form-control"
                id="name"
                value={this.state.address}
                onChange={this.handleChangeAddress}
              />
            </div>
          </div>
          <div class="form-group row">
            <label for="staticEmail" class="col-sm-2 col-form-label">
              {yawgee_language[lan].contact_person}
              <span className="text-danger">*</span>
            </label>
            <div class="col-sm-8">
              <input
                type="text"
                class="form-control"
                id="name"
                value={this.state.contact_person}
                onChange={this.handleChangeContactPerson}
              />
            </div>
          </div>
          <div class="form-group row">
            <label for="staticEmail" class="col-sm-2 col-form-label">
              {yawgee_language[lan].contact_number}
              <span className="text-danger">*</span>
            </label>
            <div class="col-sm-8">
              <input
                type="number"
                class="form-control"
                id="name"
                value={this.state.contact_number}
                onChange={this.handleChangeContactNumber}
              />
            </div>
          </div>
          <div class="form-group row">
            <label for="staticEmail" class="col-sm-2 col-form-label">
              {yawgee_language[lan].yawGee_number}
              <span className="text-danger">*</span>
            </label>
            <div class="col-sm-8">
              <input
                type="text"
                class="form-control"
                id="name"
                value={this.state.yawgee_number}
                onChange={this.handleChangeYawGeeNumber}
              />
            </div>
          </div>
          <div class="form-group row">
            <label for="staticEmail" class="col-sm-2 col-form-label">
              {yawgee_language[lan].building}
              <span className="text-danger">*</span>
            </label>
            <div class="col-sm-8">
              <input
                type="text"
                class="form-control"
                id="name"
                value={this.state.building}
                onChange={this.handleChangeBuilding}
              />
            </div>
          </div>
          <div class="form-group row">
            <label for="staticEmail" class="col-sm-2 col-form-label">
              {yawgee_language[lan].room}
              <span className="text-danger">*</span>
            </label>
            <div class="col-sm-8">
              <input
                type="text"
                class="form-control"
                id="name"
                value={this.state.room}
                onChange={this.handleChangeRoom}
              />
            </div>
          </div>
          <div class="form-group row">
            <label for="staticEmail" class="col-sm-2 col-form-label">
              {yawgee_language[lan].difficulties}
              <span className="text-danger">*</span>
            </label>
            <div class="col-sm-8">
              <input
                type="text"
                class="form-control"
                id="name"
                value={this.state.difficulties}
                onChange={this.handleChangeDifficulties}
              />
            </div>
          </div>
          <div className="action-error">
            <span>{this.props.errorMsg}</span>
          </div>
          <button
            type="button"
            class="btn btn-primary pull-right"
            onClick={this.onSubmit}
            disabled={isDisable}
          >
            <i class="fa fa-save"></i>
            &nbsp;
            {addedit_language[lan].save}
          </button>
        </form>
      </div>
    );
  }
}

export default YawGeeAddEdit;
