// user_obj = {
//   current_user_id: data.data.user_id,
//   user_name: data.data.username,
//   user_role_id: data.data.user_role_id,
//   user_role_name: data.data.user_role_name,
//   employee_id: data.data.employee_id,
//   employee_name: data.data.employee_name,
//   designation_id: data.data.designation_id,
//   designation_name: data.data.designation_name,
//   department_id: data.data.department_id,
//   department_name: data.data.department_name,
//   is_visible_all_inbox: data.data.is_visible_all_inbox,
//   is_editable_all_inbox: data.data.is_editable_all_inbox,
//   is_visible_special_setup: data.data.is_visible_special_setup,
// };

var CryptoJS = require("crypto-js");

let user_obj = window.sessionStorage.getItem("user_obj");
let decryptedUserObj = {};

if (user_obj) {
  var bytes = CryptoJS.AES.decrypt(user_obj.toString(), "secret key 123");
  decryptedUserObj = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
}

exports.user_obj = decryptedUserObj;
