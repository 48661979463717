import React, { Component } from "react";
import Login from "./components/Login/Login";
import Sidebar from "./components/Navigation/sidebar";
import Navbar from "./components/Navigation/navbar";
import Routing from "./components/Navigation/routing";
import { api } from "./utils/urlpath";

var md5 = require("md5");

var CryptoJS = require("crypto-js");
var session_user = window.sessionStorage.getItem("user_obj");

export default class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      langIndex: 1,
      is_login: true
    };
  }

  componentDidMount() {
    if (session_user) {
      this.setState({ is_login: false });
    } else {
      this.setState({ is_login: true });
    }
  }

  handleLoginButton = (username, password) => {
    try {
      this.checkAuthority(username, password);

      // window.location.replace("/");
    } catch (ex) {
      console.log(ex);
    }
  };

  handleLogoutButton = e => {
    window.sessionStorage.clear();
    this.setState({
      is_login: true,
      is_register: false,
      error_msg: ""
    });
  };

  checkAuthority = (username, password) => {
    try {
      if (username != null && password != null) {
        fetch(
          api + "Useraccount/CheckAuthority/" + username + "/" + md5(password)
        )
          .then(res => res.json())
          .then(data => {
            if (data.status) {
              this.fetchUserInfo(data.data.id);
            } else {
              this.setState({
                error_msg: data.text
              });
            }
          })
          .catch(e => console.log(e.text));
      }
    } catch (ex) {
      console.log(ex);
    }
  };

  fetchUserInfo = user_id => {
    try {
      fetch(api + "Useraccount/GetUserInfo/" + user_id)
        .then(res => res.json())
        .then(data => {
          if (data.status) {
            let user_obj = {
              current_user_id: data.data.user_id,
              user_name: data.data.username
            };

            var ciphertext = CryptoJS.AES.encrypt(
              JSON.stringify(user_obj),
              "secret key 123"
            );
            window.sessionStorage.removeItem("user_obj");
            window.sessionStorage.setItem("user_obj", ciphertext);
            this.setState({
              error_msg: "",
              is_login: false,
              is_register: false
            });
            window.location.replace("/");
          }
        })
        .catch(e => console.log("Get User Info Error : " + e));
    } catch (ex) {
      console.log(ex);
    }
  };

  render() {
    return (
      <React.Fragment>
        {this.state.is_login ? (
          <Login
            isLogin={this.state.is_login}
            onLoginClick={this.handleLoginButton}
          />
        ) : (
          <React.Fragment>
            <Sidebar langIndex={this.state.langIndex} />
            <Navbar
              onRef={ref => (this.child = ref)}
              langIndex={this.state.langIndex}
              onChangeLanguage={this.handleChangeLanguage}
              onLogout={this.handleLogoutButton}
              onNotiClick={this.handleNotiStatusClick}
              clickSaveModal={this.clickSaveModal}
              isLogin={this.props.is_login}
            />
            <Routing
              langIndex={this.state.langIndex}
              // isImplementation={this.state.is_implementation}
              // isDetail={this.state.is_detail}
              projectObj={this.state.project_obj}
              caseName={this.state.caseName}
              onNearestDeadlineClick={this.handleNearestDeadlineClick}
              onDetailClick={this.onDetailClick}
            />
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}
