import React, { Component } from "react";
import { menu_language } from "../../utils/language";
import { Link } from "react-router-dom";
var moment = require("moment-timezone");

export default class Navbar extends Component{
    render(){
        const lan = this.props.langIndex;
        return(
            <React.Fragment>
                <div id="content">
          <nav className="navbar navbar-expand-lg navbar-light bg-light ">
            <div className="container-fluid ">
              <div className="col-2 p-0">
                <button
                  type="button"
                  id="sidebarCollapse"
                  className="btn btn-info"
                >
                  <i className="fa fa-align-justify" />
                </button>
              </div>
              <div className="col-10 ">
                <div className="row">
                  <div className="nav-menus col-12 text-right">
                    <div class="dropdown">
                      <span
                        id="nav_language"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        {menu_language[lan].language}&nbsp;
                      </span>
                      <i className="fa fa-angle-down pr-4" />

                      <div
                        className="dropdown-menu dropdown-menu-right dropdown-info"
                        aria-labelledby="nav_language"
                        id="div-language"
                      >
                        <span
                          className="dropdown-item"
                          data-lan="eng"
                          onClick={this.props.onChangeLanguage}
                        >
                          English
                        </span>
                        <span
                          className="dropdown-item"
                          data-lan="mya"
                          onClick={this.props.onChangeLanguage}
                        >
                          Myanmar
                        </span>
                      </div>
                    </div>
                    <div className="dropdown">
                      <span
                        id="nav-noti"
                        className="pr-4 nav-noti"
                        data-toggle="dropdown"
                        // aria-haspopup="true"
                        // aria-expanded="false"
                      >
                        <i className="fa fa-bell" />
                        <span
                          className="noti-status"
                          // style={{
                          //   display: this.state.unseen_noti ? "inline" : "none"
                          // }}
                        />
                      </span>
                      {/* <div
                        className="dropdown-menu dropdown-menu-right dropdown-info"
                        aria-labelledby="nav-noti"
                        id="div-noti-dropdown"
                      >
                        {this.state.noti_lst.length > 0
                          ? this.state.noti_lst.map((noti, index) => (
                              <div
                                className="col div-noti"
                                key={index}
                                style={{
                                  "background-color": noti.is_read
                                    ? "white"
                                    : "rgb(251, 250, 250)"
                                }}
                              >
                                <Link
                                  to={
                                    noti.noti_category == "detail"
                                      ? "/Project"
                                      : noti.noti_category == "implementation"
                                      ? "/Implementations"
                                      : "/Case"
                                  }
                                  onClick={() => this.handleNotiClick(noti)}
                                  className="noti-txt"
                                >
                                  {noti.noti}
                                  <label className="noti-date">
                                    {moment(noti.log_time).format(
                                      "YYYY-MMM-DD hh:mm:ss a"
                                    )}
                                  </label>
                                </Link>
                              </div>
                            ))
                          : ""}
                        <Link to="/Noti">
                          <div className="noti-more">View More...</div>
                        </Link>
                      </div> */}
                    </div>

                    {/* <div>
                      <span id="nav_activitylog">
                        <Link to="/ActivityLog">
                          <i className="fa fa-clipboard-list pr-4" />
                        </Link>
                      </span>
                    </div> */}
                    {/* <span id="nav-logout" onClick={this.props.onLogout}>
                      <i className="fa fa-sign-out" />{" "}
                      {menu_language[lan].logout}
                    </span> */}
                    <div class="dropdown">
                      <span
                        id="nav_language"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        {menu_language[lan].account}&nbsp;
                      </span>
                      <i className="fa fa-angle-down pr-4" />

                      <div
                        className="dropdown-menu dropdown-menu-right dropdown-info"
                        aria-labelledby="nav_language"
                        id="div-language"
                      >
                        {/* Change Password
                        <div>
                          <button
                            type="button"
                            className="dropdown-item"
                            data-toggle="modal"
                            data-target="#change_password"
                          >
                            {menu_language[lan].change_password}
                          </button>
                        </div> */}
                        <div
                          id="nav-logout"
                          onClick={this.props.onLogout}
                          className="dropdown-item"
                        >
                          {menu_language[lan].logout}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </nav>
          
        </div>
            </React.Fragment>
        )
    }
}